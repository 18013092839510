

export const homeSliderContent3 = [
    {
        image: require("../../images/main-slider/banner4.jpg"),
        title: "Transform Your Space with Aroma's Durable PVC Flooring!",
        titleAr: "حوّل مساحتك مع أرضيات PVC المتينة من أروما!"
    },
    {
        image: require("../../images/main-slider/banner9.jpg"),
        title: "Luxury Flooring Made Affordable with Aroma PVC Solutions",
        titleAr: "أرضيات فاخرة بأسعار معقولة مع حلول أروما لأرضيات PVC"
    },
    {
        image: require("../../images/main-slider/banner3.jpg"),
        title: "Step Into Comfort: Aroma PVC Flooring for Every Style",
        titleAr: "خطوة نحو الراحة: أرضيات PVC من أروما لكل الأذواق"
    },
]
