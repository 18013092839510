import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header';
import Footer from '../layout/footer';
import { withTranslation } from 'react-i18next';
import '../../i18n';  // Import the i18n config
import emailjs from '@emailjs/browser';  // Import EmailJS
import { toast, ToastContainer } from 'react-toastify';  // Import toast components
import 'react-toastify/dist/ReactToastify.css';
const bg = require('../../images/banner/bnr1.jpg');

class Contact2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
          user_name: '',
          user_email: '',
          message: '',
          contact_number: '',
        };
    }

    // Handle form input change
    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    // Handle form submission
    handleSubmit = (event) => {
        event.preventDefault();

        emailjs.sendForm(
            'service_0opucgq',   // replace with your EmailJS service ID
            'template_nayqvpt',  // replace with your EmailJS template ID
            event.target,        // event.target represents the form data
            '8ysQnf9d4pfVe-hct'  // replace with your EmailJS public key
        )
        .then((result) => {
            toast.success('Your message has been sent successfully!');
            this.setState({
              user_name: '',
              user_email: '',
              message: '',
              contact_number: ''
          });
        })
        .catch((error) => {
          toast.error('Failed to send the message. Please try again.');
        });
    };

    render() {
        const { t } = this.props;

        return (
            <>
            <ToastContainer  style={{ zIndex: 999999999 }} />
                <Header activePage="contact" />
                
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: `url(${bg})` }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">{t('ContactUs')}</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="index">{t('Home')}</Link></li>
                                        <li>{t('ContactUs')}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full content-inner contact-page-8 bg-white">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-12">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 m-b30">
                                            <div className="icon-bx-wraper expertise bx-style-1 p-a20 radius-sm">
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">
                                                        <span className="icon-sm text-primary"><i className="ti-location-pin"></i></span> 
                                                        {t('CompanyAddress')}
                                                    </h5>
                                                    <p>{t('Address')}</p>
                                                    <h6 className="m-b15 text-black font-weight-400">
                                                        <i className="ti-alarm-clock"></i> {t('OfficeHours')}
                                                    </h6>
                                                    <p className="m-b0">{t('Hours')}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-6 m-b30">
                                            <div className="icon-bx-wraper expertise bx-style-1 p-a20 radius-sm">
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">
                                                        <span className="icon-sm text-primary"><i className="ti-email"></i></span> 
                                                        {t('Email')}
                                                    </h5>
                                                    <p className="m-b0">info@aromaegypt.com</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-6 m-b30">
                                            <div className="icon-bx-wraper expertise bx-style-1 p-a20 radius-sm">
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">
                                                        <span className="icon-sm text-primary"><i className="ti-mobile"></i></span> 
                                                        {t('PhoneNumbers')}
                                                    </h5>
                                                    <p className="m-b0">00201070562111</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-12 m-b30">
                                    <form className="inquiry-form wow fadeInUp" data-wow-delay="0.2s" onSubmit={this.handleSubmit}>
                                        <h3 className="title-box font-weight-300 m-t0 m-b10">{t('Title')} <span className="bg-primary"></span></h3>
                                        <p>{t('ContactFormText')}</p>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-user text-primary"></i></span>
                                                        <input name="user_name" type="text" required className="form-control" placeholder={t('FormName')}  value={this.state.user_name}  onChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-mobile text-primary"></i></span>
                                                        <input name="contact_number" type="text" required className="form-control" placeholder={t('FormPhone')} value={this.state.contact_number} onChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <div className="input-group"> 
                                                        <span className="input-group-addon"><i className="ti-email text-primary"></i></span>
                                                        <input name="user_email" type="email" className="form-control" required placeholder={t('FormEmailId')}  value={this.state.user_email}  onChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <textarea name="message" rows="4" className="form-control" required placeholder={t('FormText')} value={this.state.message}  onChange={this.handleInputChange}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <button name="submit" type="submit" className="site-button button-md"> <span>{t('FormButton')}</span> </button>
                                            </div>
                                        </div>
                                    </form>	
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <Footer/>    
                   
            </>
        );
    }
}

export default withTranslation()(Contact2);
