// import React from 'react';
import React, { Component } from 'react';
import Router from './markup/router';
import './App.css';

import './plugins/fontawesome/css/font-awesome.min.css';
import './plugins/line-awesome/css/line-awesome.min.css';
import './plugins/flaticon/flaticon.css';
import './plugins/flaticon/beer/flaticon.css';
import './i18n'; 
import { withTranslation } from 'react-i18next';
import StickyIcon from './markup/element/StickyIcon';
import WhatsAppIcon from './markup/element/WhatsAppIcon';
// import './css/plugins.css';
// import './css/style.css';

// import './css/templete.min.css';








class App extends Component {
  
  componentDidMount() {
    const savedLanguage = localStorage.getItem('language') || 'en'; 
    this.changeLanguage(savedLanguage, false); // No reload when page loads
  }

  changeLanguage = (lang, shouldReload = true) => {
    this.props.i18n.changeLanguage(lang);  
    localStorage.setItem('language', lang);
    
    // Update the document's direction based on language
    if (lang === 'ar') {
      document.documentElement.setAttribute('dir', 'rtl');
      document.documentElement.setAttribute('lang', 'ar'); 
    } else {
      document.documentElement.setAttribute('dir', 'ltr');
      document.documentElement.setAttribute('lang', 'en'); 
    }
    
    // Reload the page only if this was triggered by a button click
    if (shouldReload) {
      window.location.reload();
    }
  }

  render() {
    const { i18n } = this.props;
    const direction = i18n.language === 'ar' ? 'right' : 'left';
    return (
      <div className="App">
        <Router />
        <StickyIcon onLanguageChange={this.changeLanguage} />
        <WhatsAppIcon direction={direction} /> {/* Pass direction prop */}
      </div>
    );
  }
}

export default withTranslation()(App);