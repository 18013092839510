import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'
import ProductGallerySlider from '../element/productGallerySlider';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import LazyLoad from 'react-lazyload';
const bg = require('../../images/banner/bnr5.jpg')

class shopProductDetails extends Component {
    

    render() {
        const { t } = this.props;
        const products = [
            { 
                id: 1, 
                name: 'Imitation wood grain glue down PVC flooring', 
                nameAr: "أرضية PVC ملصقة بتصميم خشبي مزيف", 
                image: '../../images/product/item1.png', 
                description: "Thick, wear-resistant and comfortable imitation wood grain PVC floor. Easy to install and healthy and environmentally friendly, the first choice for home decoration.", 
                descriptionAr :"أرضية PVC بتصميم خشبي مزيف سميكة ومقاومة للاستخدام ومريحة. سهلة التركيب وصحية وصديقة للبيئة، الخيار الأول لتزيين المنزل."
            },
        ];
        const { id } = this.props.match.params;
        const product = products.find(p => p.id === parseInt(id));
        const currentLanguage = localStorage.getItem('language') || 'en'; 
        if (!product) {
            return <div>Product not found.</div>; 
        }
        return (
            <>
            <Header/>
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">{t('ProductDetails')}</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">{t('Home')}</Link></li>
                                        <li>{t('ProductDetails')}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="section-full content-inner bg-white">
                        <div className="container woo-entry">
                            <div className="row m-b30">
                                <div className="col-md-5 col-lg-5 col-sm-12 m-b30">
                                    <ProductGallerySlider/>
                                </div>
                                <div className="col-md-7 col-lg-7 col-sm-12">
                                    <form method="post" className="cart sticky-top">
                                        <div className="dlab-post-title">
                                            <h4 className="post-title"><Link to="#">{currentLanguage === 'ar' ? product.nameAr : product.name}</Link></h4>
                                            <p style={{ textAlign: 'justify' }} className="m-b10">{currentLanguage === 'ar' ? product.descriptionAr : product.description}</p>
                                            <p className="m-b10" style={{ textAlign: 'justify' }}>
                                                # Size: 2*25M......etc <br />
                                                # Thickness: 2.0MM......etc <br />
                                                # Certificate: SGS、Floor Score... <br />
                                                # Free sample: Conventional products
                                            </p>
                                            <div className="dlab-divider bg-gray tb15">
                                                <i className="icon-dot c-square"></i>
                                            </div>
                                        </div>
                                        <div className="dlab-divider bg-gray tb15">
                                            <i className="icon-dot c-square"></i>
                                        </div>
                                        <div className="row">
                                        </div>
                                        <div className="col-lg-3 d-flex">
                                        <Link Link to="/contact" className="site-button white align-self-center outline ms-auto outline-2 btnhover14 bg-primary">{t('GetInTouchButton')} </Link>
                                        </div>
                                      </form>
                                </div>
                            </div>
                            <div className="row m-b30"  style={{ direction: 'ltr' }}>
                                <div className="col-lg-12">
                                    <div className="dlab-tabs  product-description tabs-site-button">
                                        <Tabs>
                                            <TabList className="nav nav-tabs ">
                                                <Tab selectedClassName="tab-active">
                                                    <Link  to="#" >
                                                        <i className="fa fa-globe"></i> Description
                                                    </Link>
                                                </Tab>
                                                <Tab selectedClassName="tab-active">
                                                    <Link  to="#">
                                                        <i className="fa fa-photo"></i> Paving Display
                                                    </Link>
                                                </Tab>
                                                <Tab selectedClassName="tab-active">
                                                    <Link  to="#">
                                                        <i className="fa fa-magic"></i> Color Selection
                                                    </Link>
                                                </Tab>
                                                <Tab selectedClassName="tab-active">
                                                    <Link  to="#g">
                                                        <i className="fa fa-cog"></i> Specifications
                                                    </Link>
                                                </Tab>
                                            </TabList>
                                        
                                            <TabPanel className="tab-pane">
                                            <table className="table table-bordered" >
                                                    <tr className='bg-primary'>
                                                    <td colSpan="2">Imitation wood grain glue down PVC flooring</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Category</td>
                                                        <td>PVC Flooring</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Type</td>
                                                        <td>Roll</td>
                                                    </tr>
                                                    <tr className='bg-primary'>
                                                        <td>Color</td>
                                                        <td>Wood pattern</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Size</td>
                                                        <td>
                                                        2m(W)*25m（L）
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Thickness</td>
                                                        <td>2.0mm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Package</td>
                                                        <td>Transparent</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Fire-proof level</td>
                                                        <td>Bf1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Application</td>
                                                        <td>House, studio, office, school</td>
                                                    </tr>
                                                    <tr className='bg-primary'>
                                                        <td>Install</td>
                                                        <td>Glue down</td>
                                                    </tr>
                                                </table>
                                                <div>
                                                <h3>AROMA PVC Flooring Product Overview</h3>
                                                <p>❶ Clear texture and durable</p>
                                                
                                                <p>❷ Waterproof and moisture-proof, easy to clean</p>
                                                <p>It has anti-seepage water and good moisture-proof performance. Easy to take care of, saving time and effort.</p>

                                                <p>AROMA is a green new material manufacturer integrating design, research and development, production, and service. If you want to purchase PVC flooring, please contact us!</p>

                                                <h4>Many Reasons To Choose This Floor</h4>
                                                <div className="section-content box-sort-in m-b10 p-b0 button-example m-b30">
											<div className="row">
                                                <ul className="list-star primary list-box style1">
                                                    <li><strong>Green:</strong> The main raw material used in PVC flooring is polyvinyl chloride, which is an environmentally friendly, non-toxic, and renewable resource.</li>
                                                    <li><strong>Waterproof:</strong> Polyvinyl chloride has no affinity for water.</li>
                                                    <li><strong>Anti-stress:</strong> Strong supporting force and good compression resistance.</li>
                                                    <li><strong>Fire retardant:</strong> Non-flammable, prevents burning.</li>
                                                    <li><strong>Sound absorption and noise reduction:</strong> Fully absorbs sound and provides insulation.</li>
                                                    <li><strong>Anti-fouling and anti-bacterial:</strong> Expert technology makes seamless joints possible.</li>
                                                    <li><strong>Easy to clean:</strong> Can be cleaned with a damp mop, saving time and effort.</li>
                                                    <li><strong>Simple stitching:</strong> Easy and simple cutting and stitching.</li>
                                                    <li><strong>Easy to install:</strong> The floor uses a glue-down installation method for quick construction.</li>
                                                    <li><strong>Variety of colors:</strong> Realistic wood grain and a rich, vibrant color palette.</li>
                                                    <li><strong>Wide range of applications:</strong> Unique materials, superb performance, reasonable pricing, and high safety.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        </div>
                                        {/* <div className="content-block">
                                                <div className="section-full content-inner bg-white">
                                                    <div className="container">
                                                        <div className="row align-items-center">
                                                            <div className="col-lg-6 col-md-12 m-b30">
                                                                <div className="our-story">
                                                                    <h4 style={{ textAlign: 'justify' }} className="title">Flooring Structure</h4>
                                                                    <ul className="list-heart primary rounded border">
                                                                        <li><strong>UV Special Surface Treatment:</strong> Inhibits bacteria, resists dirt, is easy to clean, and prevents discoloration.</li>
                                                                        <li><strong>Protective Wear Layer:</strong> Prevents wear and is tear-resistant.</li>
                                                                        <li><strong>Printing Layer:</strong> Features a realistic material texture with a natural style.</li>
                                                                        <li><strong>Glass Fiber Layer:</strong> Guarantees size stability and crush resistance.</li>
                                                                        <li><strong>Underlayer PVC Backing:</strong> Maintains size stability in hot and humid environments.</li>
                                                                    </ul>
                                                                    {/* <Link to={"/#"} className="site-button">Read More</Link> 
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-12 m-b30 ">
                                                            <LazyLoad>
                                                                <img src={require("../../images/structure.webp")} className="radius-sm" alt=""/>
                                                            </LazyLoad>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}

                                            <div className="content-block">
                                                <div className="section-full content-inner bg-white">
                                                    <div className="container">
                                                        <div className="row align-items-center">
                                                        <div className="col-lg-6 col-md-12 m-b30">
                                                            <LazyLoad>
                                                                <img src={require("../../images/packing.webp")} className="radius-sm" alt=""/>
                                                            </LazyLoad>
                                                            </div>
                                                            <div className="col-lg-6 col-md-12 m-b30">
                                                                <div className="our-story">
                                                                <table className="table table-bordered">

                                                                        <tr className='bg-primary'>
                                                                            <th colSpan="3" style={{ textAlign: 'center' }}>Packing Information</th>

                                                                        </tr>

                                                                        <tr>
                                                                            <td>Floor Thickness (mm)</td>
                                                                            <td>1.0 mm</td>
                                                                            <td>2.0 mm</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Width per Roll (m)</td>
                                                                            <td>2</td>
                                                                            <td>2</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Length of Each Roll (m)</td>
                                                                            <td>25</td>
                                                                            <td>25</td>
                                                                        </tr>
                                                                        <tr className='bg-primary'>
                                                                            <td>Roll Diameter (cm)</td>
                                                                            <td>20.0</td>
                                                                            <td>25.0</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Area per Volume (㎡)</td>
                                                                            <td>50</td>
                                                                            <td>50</td>
                                                                        </tr>
                                                                        <tr className='bg-primary'>
                                                                            <td>Weight per Square Meter (kg)</td>
                                                                            <td>2.4</td>
                                                                            <td>3.2</td>
                                                                        </tr>
                                                                </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </TabPanel>

                                            <TabPanel className="tab-pane ">
                                            <h4 className="post-title">Scenario shows</h4>
                                                <p className="m-b10">Imitation wood grain PVC floor rolls, suitable for installation and use in apartments, activity rooms and other places. You don't need to ask someone to install it, you can handle it yourself. Convenient and fast, easy check-in.
                                                    You can choose our existing colors, and we can also customize the colors you need according to your preferences and needs.</p>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 m-b30">
                                                <LazyLoad>
                                                    <img src={require("../../images/paving.webp")} className="radius-sm" alt=""/>
                                                </LazyLoad>
                                                </div>
                                            </TabPanel>
                                            <TabPanel className="tab-pane">
                                            <h4 className="post-title">Color for you</h4>
                                                <p className="m-b10">You can choose our existing colors, and we can also customize the colors you need according to your preferences and needs.</p>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 m-b30">
                                                <LazyLoad>
                                                    <img src={require("../../images/colors2.png")} className="radius-sm" alt=""/>
                                                </LazyLoad>
                                                </div>
                                            </TabPanel>
                                            <TabPanel className="tab-pane ">
                                            <table className="table table-bordered">
                                                <tr >
                                                    <td colSpan="3" style={{ textAlign: 'center' }}>PVC FLOORING PRODUCT SPECIFICATIONS</td>
                                                </tr>
                                                <tr className='bg-primary'>
                                                    <td>Description</td>
                                                    <td>Standard</td>
                                                    <td>Heterogeneous PVC Flooring</td>
                                                </tr>
                                                <tr>
                                                    <td>Type of Flooring</td>
                                                    <td>EN649</td>
                                                    <td>Heterogeneous Sheet</td>
                                                </tr>
                                                <tr>
                                                    <td>Total Thickness</td>
                                                    <td>EN428</td>
                                                    <td>2.0 mm</td>
                                                </tr>
                                                <tr>
                                                    <td>Weight</td>
                                                    <td>EN430</td>
                                                    <td>3200g/㎡</td>
                                                </tr>
                                                <tr>
                                                    <td>Width</td>
                                                    <td>EN426</td>
                                                    <td>2m</td>
                                                </tr>
                                                <tr>
                                                    <td>Length Sheet</td>
                                                    <td>EN426</td>
                                                    <td>20m</td>
                                                </tr>
                                                <tr className='bg-primary'>
                                                    <td colSpan="3" style={{ textAlign: 'center' }}>Classification</td>
                                                </tr>
                                                <tr>
                                                    <td>Fire Rating</td>
                                                    <td>GB 8624-2012</td>
                                                    <td>BI(Bf1-s1)</td>
                                                </tr>
                                                <tr>
                                                    <td>Toxic Testing</td>
                                                    <td>GB 18586-2001</td>
                                                    <td>Good</td>
                                                </tr>
                                                <tr>
                                                    <td>Electrical Resistance</td>
                                                    <td>EN1081</td>
                                                    <td>≤10^9</td>
                                                </tr>
                                                <tr>
                                                    <td>Slip Resistance Dry</td>
                                                    <td>EN13893</td>
                                                    <td>≥0.3</td>
                                                </tr>
                                                <tr>
                                                    <td>Slip Resistance Wet</td>
                                                    <td>EN51 130</td>
                                                    <td>R9</td>
                                                </tr>
                                                <tr className='bg-primary' style={{ textAlign: 'center' }}>
                                                    <td colSpan="3">Performances</td>
                                                </tr>
                                                <tr>
                                                    <td>Abrasion Group</td>
                                                    <td>EN660-2</td>
                                                    <td>T</td>
                                                </tr>
                                                <tr>
                                                    <td>Castor Wheel Test</td>
                                                    <td>EN425</td>
                                                    <td>Suitable</td>
                                                </tr>
                                                <tr>
                                                    <td>Color Fastness</td>
                                                    <td>EN 20 105-B02</td>
                                                    <td>6</td>
                                                </tr>
                                                <tr>
                                                    <td>Chemical Products Resistance</td>
                                                    <td>EN423</td>
                                                    <td>Good</td>
                                                </tr>
                                                <tr>
                                                    <td>Dimensional Stability</td>
                                                    <td>EN434</td>
                                                    <td>≤0.40</td>
                                                </tr>
                                                <tr>
                                                    <td>Flexibility</td>
                                                    <td>EN435</td>
                                                    <td>Excellent</td>
                                                </tr>
                                                <tr>
                                                    <td>Hygiene Treatment</td>
                                                    <td>MRSA</td>
                                                    <td>Very Good</td>
                                                </tr>
                                                <tr>
                                                    <td>Impact Sound Reduction</td>
                                                    <td>ENISO717/12</td>
                                                    <td>Approx. +4dB</td>
                                                </tr>
                                                <tr>
                                                    <td>Indentation</td>
                                                    <td>EN433</td>
                                                    <td>≈0.05mm</td>
                                                </tr>
                                                <tr>
                                                    <td>Residual Indentation</td>
                                                    <td>EN433</td>
                                                    <td>≤0.03~0.1mm</td>
                                                </tr>
                                                <tr>
                                                    <td>Surface Treatment</td>
                                                    <td>UV</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Curl Resultant to Heat</td>
                                                    <td>EN434</td>
                                                    <td>1mm</td>
                                                </tr>
                                                <tr>
                                                    <td>Anti-bacterial & Fungicidal</td>
                                                    <td></td>
                                                    <td>Good</td>
                                                </tr>
                                                <tr>
                                                    <td>VOC</td>
                                                    <td>GB18586</td>
                                                    <td>Pass</td>
                                                </tr>
                                            </table>
                                            </TabPanel>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                     <div className="section-full call-action bg-primary wow fadeIn" data-wow-duration="2s" data-wow-delay="0.9s">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-9 text-white">
                                    <h2 className="title">{t('GetInTouchTitle')}</h2>
                                    <p className="m-b0">{t('GetInTouchText')}</p>
                                    </div>
                                    <div className="col-lg-3 d-flex">
                                        <Link to="/contact" className="site-button white align-self-center outline ms-auto outline-2 btnhover14">{t('GetInTouchButton')}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>  
                <Footer hideContactInfo />              
            </>
        )
    }
}
export default withTranslation()(shopProductDetails);