import React, { useState, useEffect, useRef } from 'react';
import '../../../src/css/StickyIcon.css'; 
import Flag from 'react-flagkit';
import { IoEarth } from "react-icons/io5";
const StickyIcon = ({ onLanguageChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [lang, setLang] = useState(localStorage.getItem('language') || 'en');
    const iconRef = useRef(null);
    const dropdownRef = useRef(null);
  
    const toggleMenu = () => setIsOpen(prevState => !prevState);
  
    const changeLanguage = (lang) => {
      onLanguageChange(lang);
      setLang(lang);
      localStorage.setItem('language', lang);
      setIsOpen(false); // Close the dropdown after selecting
    };
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !iconRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);
  
    useEffect(() => {
      if (dropdownRef.current && iconRef.current) {
        const dropdown = dropdownRef.current;
        const iconRect = iconRef.current.getBoundingClientRect();
        const dropdownRect = dropdown.getBoundingClientRect();
        const viewportWidth = window.innerWidth;
  
        // Calculate and set the dropdown position
        dropdown.style.bottom = `${iconRect.height + 20}px`; // Position above the icon
  
        if (lang === 'ar') {
          dropdown.style.right = `${Math.max(0, Math.min(viewportWidth - dropdownRect.width, window.innerWidth - iconRect.right))}px`;
          dropdown.style.left = 'auto'; // Reset left positioning for RTL
        } else {
          dropdown.style.left = `${Math.max(0, Math.min(viewportWidth - dropdownRect.width, iconRect.left))}px`;
          dropdown.style.right = 'auto'; // Reset right positioning for LTR
        }
      }
    }, [isOpen, lang]);
  
    return (
      <div className={`sticky-icon ${lang === 'ar' ? 'right' : 'left'}`}>
        <div className="icon" onClick={toggleMenu} ref={iconRef}>
        <IoEarth />
        </div>
        {isOpen && (
          <div className="dropdown" ref={dropdownRef}>
            <div onClick={() => changeLanguage('en')}>
              <Flag country="US" /> English
            </div>
            <div onClick={() => changeLanguage('ar')}>
              <Flag country="EG" /> Arabic
            </div>
          </div>
        )}
      </div>
    );
};

export default StickyIcon;