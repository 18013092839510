import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'
import { withTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
const bg = require('../../images/banner/bnr4.jpg')


class Shop extends Component {


    render() {
        const { t } = this.props;
        const products = [
            { id: 1, name: 'Products1', image: '../../images/product/item1.png' },
        ];
        
        return (
            <>
                <Header activePage="products"/>
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">{t('Products')}</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">{t('Home')}</Link></li>
                                        <li>{t('Products')}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-full content-inner">
                        <div className="container">
                            <div className="row">
                                {products.map(product => (
                                    <div className="col-lg-3 col-md-4 col-sm-6" key={product.id}>
                                        <div className="item-box m-b10">
                                            <div className="item-img">
                                            <LazyLoad>
                                                <img src={require(`../../images/product/item1.png`)} alt={product.name} />
                                            </LazyLoad>
                                                <div className="item-info-in">
                                                    <ul>
                                                        <li><Link to={`/products/${product.id}`}><i className="ti-eye"></i></Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="item-info text-center text-black p-a10">
                                                <h6 className="item-title font-weight-500">
                                                    <Link to=
                                                            {{
                                                                pathname: `/products/${product.id}`,
                                                                state: { product }
                                                            }}>{t('Products1')}
                                                    </Link>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>                
                <Footer hideContactInfo />                
            </>
        )
    }
}
export default withTranslation()(Shop);