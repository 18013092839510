import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer3';
// Sliders
import {homeSliderContent3} from '../element/SliderContent'
import HomeSlider from '../element/homeslider1'
import { PiHouse } from "react-icons/pi";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { GiOfficeChair } from "react-icons/gi";
import { IoIosSchool } from "react-icons/io";
import { PiHospital } from "react-icons/pi";
import { withTranslation } from 'react-i18next';
import { BsShop } from "react-icons/bs";
import LazyLoad from 'react-lazyload';
import '../../css/plugins.css';
import '../../css/style.css';
import '../../css/skin/skin-1.css';
import '../../css/templete.min.css';

const bg = require('../../images/background/map-bg.png')

class Index3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
          hoveredIcon: null, 
          isRTL: localStorage.getItem('language') === 'ar'
        };
      }
      
      handleMouseEnter = (iconId) => {
        this.setState({ hoveredIcon: iconId });
      };
      
      handleMouseLeave = () => {
        this.setState({ hoveredIcon: null });
      };
    render() {
        const { t } = this.props; 
        
        const { hoveredIcon, isRTL } = this.state;
        const iconsData = [
            { id: '01', icon: <PiHouse size="1x" />, textKey: 'Residential' },
            { id: '02', icon: <PiHospital size="1x" />, textKey: 'Healthcare' },
            { id: '03', icon: <HiOutlineBuildingOffice2 size="1x" />, textKey: 'Housing' },
            { id: '04', icon: <GiOfficeChair size="1x" />, textKey: 'Offices' },
            { id: '05', icon: <BsShop size="1x" />, textKey: 'Retail' },
            { id: '06', icon: <IoIosSchool size="1x" />, textKey: 'Education' },
          ];
          const wrapperClass = `icon-bx-wraper${isRTL ? ' icon-bx-wraper-rtl' : ''}`;
        return (
            <>
                <Header activePage="home" />
                <HomeSlider 
					data={homeSliderContent3}
					contentWrapperCls="sliderStyle3"
					hideBtn
                />

                <div className="page-content bg-white">
                    <div className="content-block">
                     
                        <div className="section-full content-inner bg-white">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 col-md-6 m-b30 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                                        <div className="our-story">
                                            <span>{t('OurProduct')}</span>
                                            <h2 className="title">{t('ProductTitleMain')}<br /> {t('ProductTitleType')}</h2>
                                            <h4 className="title">{t('ProductDesc')}</h4>
                                            <p style={{ textAlign: 'justify' }}>{t('ProductText')}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 m-b30 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
                                    <LazyLoad>
                                        <img src={require("../../images/product/p1.jpg")} className="radius-sm" alt="" />
                                    </LazyLoad>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section-full content-inner-2 bg-primary wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s" style={{ backgroundImage: "url(" + bg + ")" }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 text-center service-info">
                                    <h2 className="title text-white">{t('HomeBannerTitle')}</h2>
                                    <p>{t('HomebannerText')}</p></div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="section-full bg-gray content-inner">
                                <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="title">{t('MarketSegments')}</h2>
                                </div>
                                <div className="section-content row">
                                    {iconsData.map(({ id, icon, textKey }) => (
                                    <div
                                        key={id}
                                        className="col-xl-4 col-md-6 col-sm-12 service-box style3"
                                        onMouseEnter={() => this.handleMouseEnter(id)}
                                        onMouseLeave={this.handleMouseLeave}
                                    >
                                        <div className={wrapperClass}  data-name={id}>
                                        <div className="icon-lg">
                                            <Link to={"/#"} className="icon-cell">
                                            {React.cloneElement(icon, {
                                                style: {
                                                color: hoveredIcon === id ? '#FFF' : '#6D301E',
                                                },
                                            })}
                                            </Link>
                                        </div>
                                        <div className="icon-content">
                                            <h2 className="dlab-tilte">
                                            {hoveredIcon === id ? t(textKey) : t(textKey)}
                                            </h2>
                                        </div>
                                        </div>
                                    </div>
                                    ))}
                                </div>
                                </div>
                            </div> */}
                        {/* <div className="section-full call-action bg-primary wow fadeIn" data-wow-duration="2s" data-wow-delay="0.9s">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-9 text-white">
                                    <h2 className="title">{t('GetInTouchTitle')}</h2>
                                    <p className="m-b0">{t('GetInTouchText')}</p>
                                    </div>
                                    <div className="col-lg-3 d-flex">
                                        <Link to="/contact" className="site-button white align-self-center outline ms-auto outline-2 btnhover14">{t('GetInTouchButton')}</Link>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default  withTranslation()(Index3);