import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpApi) // Loads translations from a backend server
  .use(LanguageDetector) // Detects user language
  .use(initReactI18next) // Passes i18n down to React components
  .init({
    supportedLngs: ['en', 'ar'], // List of languages you support
    fallbackLng: 'en', // Fallback language in case of missing translations
    detection: {
      order: ['cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
      caches: ['cookie', 'localStorage'], // Where to store the detected language
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json', // Path to the translation files
    },
    react: {
      useSuspense: false, // Use Suspense or not (depends on your needs)
    },
  });

export default i18n;