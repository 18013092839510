import '../../../src/css/WhatsAppIcon.css'; 
import { IoLogoWhatsapp } from "react-icons/io";
const WhatsAppIcon = ({ direction }) => {
    const whatsappNumber = '+201070562111'; // Replace with your WhatsApp phone number
    const message = 'Hello, I would like to inquire about your services.'; // Default message
  
    const handleClick = () => {
      window.open(`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`, '_blank');
    };
  
    return (
      <div className={`whatsapp-icon ${direction}`} onClick={handleClick}>
        <IoLogoWhatsapp />
      </div>
    );
};

export default WhatsAppIcon;