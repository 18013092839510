import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'
//import { Accordion, AccordionItem } from 'react-sanfona';
import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const faqContentEn = [
    {
        question: "Is Aroma a trading company or a factory?",
        answer: "We are both a factory and a trading company (we have our own manufacturing site)."
    },
    {
        question: "How can I obtain a sample of the material to confirm its quality?",
        answer: "Please provide the exact thickness, the structure of each layer, and the surface finish you require as much as possible. We will select appropriate samples for you based on your specifications."
    },
    {
        question: "What is your sample policy?",
        answer: "For existing samples: free of charge for samples under 1 square meter. Delivery time is 3 days. For customized samples: $50 per printed solid design. As per Aroma’s policy, we would appreciate it if you could cover the shipping cost for the samples. This helps to strengthen our business relationship. We follow this practice with most of our clients and hope it works for you too. We guarantee to refund the shipping cost with your first order."
    },
    {
        question: "What is your minimum order quantity (MOQ)?",
        answer: "For regular products: 3,000 square meters per design."
    },
    {
        question: "Where is Aroma located, and how can I visit from Cairo?",
        answer: "Aroma is based in Monofeya, Egypt. You can reach us via a 1.5-hour drive from Cairo, and we would gladly assist in guiding you from there."
    },
    {
        question: "What is your delivery time?",
        answer: "Based on order and location."
    },
    {
        question: "Can you help shorten the lead time? We are running out of time to deliver to our customer.",
        answer: "In some cases, yes. We can request our factory to adjust the production schedule, provided it doesn’t affect the delivery timelines of our other customers."
    },
    {
        question: "Where do you ship from?",
        answer: "We typically ship from the nearest ports to the sourcing destinations, depending on the location of your orders."
    },
    {
        question: "What are your prices?",
        answer: "Since we offer a wide range of products, please provide specific details about your requirements. We can offer the best price based on that. If you are unsure which product is suitable, share your application details, and we will recommend the right product for you."
    },
    {
        question: "What are your payment terms?",
        answer: "Our standard payment terms are 30% advance payment by T/T, with the remaining balance due upon shipment against a copy of the bill of lading. We also accept L/C, PayPal, and Western Union."
    },
    {
        question: "Do you allow inspections before the final payment?",
        answer: "Absolutely. We are confident in the quality of our PVC flooring. Before shipping, we can provide production samples for inspection of material, thickness, surface treatment, and designs."
    },
    {
        question: "How do you handle product quality complaints?",
        answer: "We have a dedicated QC team that will send you images of the mass-produced products before delivery. We will address any issues until they are resolved, which is why many of our clients return for repeat orders."
    }
];

const faqContentAr = [
    {
        question: "هل شركة أروما تجارية أم مصنع؟",
        answer: "نحن مصنع وشركة تجارية (لدينا موقع تصنيع خاص بنا)."
    },
    {
        question: "كيف يمكنني الحصول على عينة من المادة لتأكيد الجودة؟",
        answer: "يرجى تقديم التفاصيل الدقيقة للسماكة، هيكل كل طبقة، وتشطيب السطح المطلوب. سنقوم باختيار العينات المناسبة بناءً على مواصفاتك."
    },
    {
        question: "ما هي سياسة العينة الخاصة بكم؟",
        answer: "بالنسبة للعينات الموجودة: مجانًا عند الطلبات التي لا تتجاوز 1 متر مربع. وقت التوصيل 3 أيام. بالنسبة للعينات المخصصة: 50 دولارًا لكل تصميم ثابت مطبوع. وفقًا لسياسة أروما، نقدر إذا قمت بدفع تكلفة الشحن للعينات، حيث نعتقد أن ذلك سيساهم في تطوير علاقاتنا التجارية. نحن نتبع هذه السياسة مع معظم عملائنا ونأمل أن تكون مناسبة لك أيضًا. نعدك برد تكلفة الشحن مع أول طلبية."
    },
    {
        question: "ما هو الحد الأدنى لكمية الطلب (MOQ)؟",
        answer: "للمنتجات العادية: 3000يم. متر مربع لكل تصم"
    },
    {
        question: "أين يقع مقر أروما وكيف يمكنني زيارتكم من القاهرة؟",
        answer: "يقع مقر أروما في محافظة المنوفية، مصر. يمكن الوصول إلينا في غضون ساعة ونصف بالسيارة من القاهرة، وسنكون سعداء بإرشادك للوصول."
    },
    {
        question: "ما هو وقت التسليم؟",
        answer: "يعتمد هذا على الكمية وموقع العميل."
    },
    {
        question: "هل يمكنكم تقصير مدة التنفيذ؟ نحن في حاجة لتسليم البضاعة لعميلنا في وقت محدد.",
        answer: "في بعض الحالات، نعم. يمكننا طلب إعادة جدولة الإنتاج في المصنع، بشرط ألا يؤثر ذلك على مواعيد تسليم العملاء الآخرين."
    },
    {
        question: "من أين تقومون بالشحن؟",
        answer: "نقوم بالشحن من أقرب الموانئ إلى وجهة الطلبات، حسب موقعها."
    },
    {
        question: "ما هي أسعاركم؟",
        answer: "بما أن لدينا مجموعة متنوعة من المنتجات، يرجى تزويدنا بتفاصيل دقيقة عن احتياجاتك. يمكننا تقديم أفضل سعر بناءً على ذلك. إذا لم تكن متأكدًا من المنتج المناسب، قم بإعلامنا بتفاصيل استخداماتك وسنختار المنتج المناسب لك."
    },
    {
        question: "ما هي شروط الدفع الخاصة بكم؟",
        answer: "شروط الدفع لدينا هي 30٪ دفعة مقدمة عن طريق التحويل البنكي، والباقي يتم دفعه عند الشحن مع نسخة من بوليصة الشحن. نقبل أيضًا الاعتماد المستندي، باي بال، وويسترن يونيون."
    },
    {
        question: "هل تقبلون فحص المنتجات قبل الدفع النهائي؟",
        answer: "بالتأكيد نقبل. نحن واثقون من جودة منتجاتنا من أرضيات PVC. قبل الشحن، يمكننا تقديم عينات الإنتاج للعميل لفحص المادة والسماكة ومعالجة السطح والتصاميم."
    },
    {
        question: "كيف تتعاملون مع شكاوى الجودة؟",
        answer: "لدينا فريق مراقبة الجودة الذي سيقوم بإرسال صور المنتجات لك قبل التسليم. نحن نعمل على حل أي مشكلة حتى يتم حلها، ولهذا السبب يعود إلينا العديد من العملاء بطلبات جديدة."
    }
];




const bg = require('../../images/banner/bnr3.jpg')

const Faq2 = () => {
    const [activeDefault, setActiveDefault] = useState(-1);  
    const [faqContent, setFaqContent] = useState(faqContentEn); // Default to English
    const { t } = useTranslation();
    useEffect(() => {
        // Get language from local storage
        const language = localStorage.getItem('language') || 'en';
        
        // Set FAQ content based on language
        if (language === 'ar') {
            setFaqContent(faqContentAr);
        } else {
            setFaqContent(faqContentEn);
        }
    }, []); // Only run once on mount

    return (
        <>
            <Header activePage="faqs" />
            <div className="page-content bg-white">
                <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                    <div className="container">
                        <div className="dlab-bnr-inr-entry">
                            <h1 className="text-white">{t('FAQs')}</h1>
                            <div className="breadcrumb-row">
                                <ul className="list-inline">
                                    <li><Link to="/">{t('Home')}</Link></li>
                                    <li>{t('FAQs')}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="content-block">
                    <div className="section-full bg-gray content-inner">
                        <div className="container">
                            <div className="section-head text-black">
                                <h3>{t('GeneralQuestions')}</h3>
                            </div>
                            <div className="row m-b30">
                                <div className="col-lg-12 col-sm-12">
                                    <Accordion className="dlab-accordion faq-2 accord-2 box-sort-in m-b30">
                                        {faqContent.map((faq, i) => (
                                            <div className="panel" key={i}>
                                                <Accordion.Item eventKey={`${i}`}>
                                                    <Accordion.Header as="h6">
                                                        {faq.question}
                                                    </Accordion.Header>
                                                    <Accordion.Body eventKey={`${i}`}>
                                                        <div>{faq.answer}</div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                        ))}
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Faq2;